import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Background from "./components/Background";
import Router from "./routes/Router";
import Footer from "./components/Footer";

// App Component
function App() {
  return (
    <>
      <Background />
      <Router />
      <Footer />
    </>
  );
}

export default App;
