import React, { useEffect, useRef, useState } from "react";
import Web3 from "web3";
import { useAppKitAccount } from "@reown/appkit/react";
import sttabi from "../abis/sttabi.json";
import tokenContarctABI from "../abis/tokenContarctABI.json";
import AffiliateDetails from "../components/AffiliateDetails";
import JoinOcta from "../components/JoinOcta";
import YourBonusSummary from "../components/YourBonusSummary";
import ReTopupEarnings from "../components/ReTopupEarnings";
import OctaDeElClassico from "../components/OctaDeElClassico";
import SpanishFiestaPackages from "../components/SpanishFiestaPackages";
import MyRankIcon1 from "../assets/img/1.png";
import MyRankIcon2 from "../assets/img/2.png";
import MyRankIcon3 from "../assets/img/3.png";
import MyRankIcon4 from "../assets/img/4.png";
import MyRankIcon5 from "../assets/img/5.png";
import MyRankIcon6 from "../assets/img/6.png";
import MyRankIcon7 from "../assets/img/7.png";
import MyRankIcon8 from "../assets/img/8.png";
import { Chart, registerables } from "chart.js";
import Header from "../components/Header";

Chart.register(...registerables);

const WagmiHooks = () => {
  const { address, isConnected, status } = useAppKitAccount();
  const [bscUsdBalance, setBscUsdBalance] = useState(null);
  const [bnbBalance, setBnbBalance] = useState(null);
  const [noOfDirect] = useState(null);
  const [userData, setUserData] = useState({});
  const [usersBonusData, setUsersBonusData] = useState({});
  const [userPurchaseData, setUserPurchaseData] = useState({});
  const [diamondworth, setDiamondworth] = useState({});
  const [isJoined, setIsJoined] = useState(false);
  const [isReTopupVisible, setIsReTopupVisible] = useState(false);
  const [isProPackgeVisible, setIsProPackgeVisible] = useState(false);
  const [isFiestaVisible, setFiestaVisible] = useState(false);
  const [contractInstance, setContractInstance] = useState(null);
  const [bscUsdContract, setBscUsdContract] = useState(null);
  const [rankIcon, setRankIcon] = useState(null);
  const [percent, setPercent] = useState(0);
  const [myTeamData, setMyTeamData] = useState([]);
  const [levelDownlineStats, setLevelDownlineStats] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const [proPoolData, setProPoolData] = useState({
    purchasePoolFlag: [],
    purchasePoolBtn: [],
    remainingMonth: [0, 0, 0, 0, 0, 0, 0, 0],
    claimedMonth: [0, 0, 0, 0, 0, 0, 0, 0],
    remainingDays: [0, 0, 0, 0, 0, 0, 0, 0],
    claimable: [],
  });

  const [spanishFiestaPackData, setSpanishFiestaPackData] = useState({
    SpanishFiestaPackFlag: [],
    SpanishFiestaPackBtn: [],
    remainingMonth: [0, 0, 0, 0],
    claimedMonth: [0, 0, 0, 0],
    remainingDays: [0, 0, 0, 0],
    claimable: [],
  });

  const contractAddress = "0x2323E42EB8972b9a91E337DB51AAE6A50E853eE7";
  const bscUsdContractAddress = "0xc2bEFff2b148FB54B017b133a0764ef4E7A72dcA";

  useEffect(() => {
    if (isConnected && address) {
      const web3 = new Web3(window.ethereum);

      // Initialize Contracts
      const sttContractInstance = new web3.eth.Contract(
        sttabi,
        contractAddress
      );
      setContractInstance(sttContractInstance);

      const usdtContractInstance = new web3.eth.Contract(
        tokenContarctABI,
        bscUsdContractAddress
      );
      setBscUsdContract(usdtContractInstance);

      // Fetch balances and user data
      const fetchData = async () => {
        try {
          // Fetch BSC-USD balance
          const bscUsdWeiBalance = await usdtContractInstance.methods
            .balanceOf(address)
            .call();
          const bscUsdBalance = web3.utils.fromWei(bscUsdWeiBalance, "ether");
          setBscUsdBalance(bscUsdBalance);

          // Fetch BNB balance
          const weiBalance = await web3.eth.getBalance(address);
          const bnbBalance = web3.utils.fromWei(weiBalance, "ether");
          setBnbBalance(bnbBalance);

          // Fetch user data from STT contract
          const users = await sttContractInstance.methods.users(address).call();
          setUserData({
            userId: users[0],
            referredBy: users[1],
            UsdtPurchasedAmount: web3.utils.fromWei(users[2], "ether"),
            noOfDirect: users[4],
            levelEligibility: users[5],
            rankId: users[6],
            directTeamInvestment: web3.utils.fromWei(users[8], "ether"),
            allTeamInvestment: web3.utils.fromWei(users[9], "ether"),
          });

          // Basic Packages Visible
          if (parseInt(users[0]) > 0) {
            setIsJoined(true);
            setIsReTopupVisible(true);
            setIsProPackgeVisible(true);
            const now = new Date();
            const day = now.getUTCDate();
            // Spanish Fiesta Packages Visible
            if (day >= 10 && day <= 13) {
              setFiestaVisible(true);
            } else {
              setFiestaVisible(false);
            }
          } else {
            setIsJoined(false);
            setIsReTopupVisible(false);
            setIsProPackgeVisible(false);
            setFiestaVisible(false);
          }

          // Fetch and set all required data in a single call for usersBonus
          const userBonus = await sttContractInstance.methods
            .usersBonus(address)
            .call();
          setUsersBonusData({
            totalCapping: web3.utils.fromWei(userBonus[0], "ether"),
            AchivedCapping: web3.utils.fromWei(userBonus[1], "ether"),
            referralBonus: web3.utils.fromWei(userBonus[2], "ether"),
            levelbonus: web3.utils.fromWei(userBonus[3], "ether"),
            rankRewardbonus: web3.utils.fromWei(userBonus[4], "ether"),
            monthlybonus: web3.utils.fromWei(userBonus[5], "ether"),
          });

          // Calculate Capping Percentage
          const totalCapping = parseFloat(
            web3.utils.fromWei(userBonus[0], "ether")
          );
          const AchivedCapping = parseFloat(
            web3.utils.fromWei(userBonus[1], "ether")
          );
          let CapingPercentage = (AchivedCapping / totalCapping) * 100;

          CapingPercentage = isNaN(CapingPercentage)
            ? 0
            : parseFloat(CapingPercentage.toFixed(1));

          let formattedPercentage =
            CapingPercentage < 1
              ? CapingPercentage.toString().replace(/^0+/, "")
              : CapingPercentage;

          setPercent(formattedPercentage);

          // Fetch and set all required data in a single call for UserPurchaseData
          const UserPurchase = await sttContractInstance.methods
            .userPurchaseDetails(address)
            .call();
          setUserPurchaseData({
            noofdiamond: UserPurchase[2],
            directdimaond: UserPurchase[3],
            teamdimaond: UserPurchase[4],
          });

          // Fetch and set all required data in a single call for DiamondWorthData
          const DiamondWorth = await sttContractInstance.methods
            .getDiamondWorth(address)
            .call();
          setDiamondworth({
            diamondworth: web3.utils.fromWei(DiamondWorth, "ether"),
          });

          // My Rank
          const userRank = await fetchUserRank(web3, address);
          updateRankIcon(userRank);

          // Fetch level downline data and referees My Downline List
          const response = await sttContractInstance.methods
            .level_downline(address)
            .call();

          const updatedStats = [];
          const refereesData = [];

          for (let level = 0; level <= 14; level++) {
            const membersCount = parseFloat(response[0][level]);
            const purchased = parseFloat(
              web3.utils.fromWei(response[1][level], "ether")
            ).toFixed(8);
            const levelBonus = parseFloat(
              web3.utils.fromWei(response[2][level], "ether")
            ).toFixed(8);

            if (membersCount > 0) {
              updatedStats.push({
                level: level + 1,
                membersCount,
                purchased,
                levelBonus,
              });

              // Fetch referees for the current level
              const referees = await sttContractInstance.methods
                .getReferees(address, level + 1)
                .call();

              referees.forEach((referee) => {
                refereesData.push({
                  SNo: refereesData.length + 1,
                  Level: level + 1,
                  Wallet: referee,
                });
              });
            }
          }
          setLevelDownlineStats(updatedStats);
          setMyTeamData(() => [...refereesData]);

          // Map graphData
          const graphValues = response[2] || [];
          const graphData = graphValues.map((value) =>
            parseFloat(web3.utils.fromWei(value, "ether")).toFixed(8)
          );

          if (graphData.length === 0) {
            console.warn("No graph data available", graphValues);
          }
          setGraphData(graphData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [address, isConnected]);

  // Update Chart
  useEffect(() => {
    if (chartRef.current && graphData.length > 0) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      chartInstance.current = new Chart(chartRef.current, {
        type: "bar",
        data: {
          labels: graphData.map((_, i) => `Level ${i + 1}`),
          datasets: [
            {
              data: graphData,
              backgroundColor: "#f19200",
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            y: { beginAtZero: true },
          },
        },
      });
    }
  }, [graphData]);

  const fetchUserRank = async () => {
    const rank = 0;
    return rank;
  };
  const updateRankIcon = (rank) => {
    let icon;
    switch (rank) {
      case 1:
        icon = MyRankIcon1;
        break;
      case 2:
        icon = MyRankIcon2;
        break;
      case 3:
        icon = MyRankIcon3;
        break;
      case 4:
        icon = MyRankIcon4;
        break;
      case 5:
        icon = MyRankIcon5;
        break;
      case 6:
        icon = MyRankIcon6;
        break;
      case 7:
        icon = MyRankIcon7;
        break;
      case 8:
        icon = MyRankIcon8;
        break;
      // default icon
      default:
        icon = MyRankIcon1;
        break;
    }
    setRankIcon(icon);
  };

  // const SwitchNetwork = async (chainId) => {
  //   if (window.ethereum) {
  //     try {
  //       await window.ethereum.request({
  //         method: "wallet_switchEthereumChain",
  //         params: [{ chainId: Web3.utils.toHex(chainId) }],
  //       });
  //       console.log("Network switched successfully");
  //     } catch (switchError) {
  //       // If the network is not added to MetaMask, add it
  //       if (switchError.code === 4902) {
  //         try {
  //           await window.ethereum.request({
  //             method: "wallet_addEthereumChain",
  //             params: [
  //               {
  //                 chainId: Web3.utils.toHex(chainId),
  //                 chainName: "BNB Smart Chain Mainnet",
  //                 rpcUrls: ["https://bsc-dataseed.binance.org/"],
  //                 nativeCurrency: {
  //                   name: "BNB",
  //                   symbol: "BNB",
  //                   decimals: 18,
  //                 },
  //                 blockExplorerUrls: ["https://bscscan.com/"],
  //               },
  //             ],
  //           });
  //           console.log("Network added successfully");
  //         } catch (addError) {
  //           console.error("Failed to add network", addError);
  //         }
  //       } else {
  //         console.error("Failed to switch network", switchError);
  //       }
  //     }
  //   } else {
  //     console.log("MetaMask is not installed");
  //   }
  // };
  // SwitchNetwork(56);

  const SwitchNetwork = async (chainId) => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(chainId) }],
        });
        // console.log("Network switched successfully");
      } catch (switchError) {
        // If the network is not added to MetaMask, add it
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: Web3.utils.toHex(chainId),
                  chainName: "'Smart Chain - Testnet",
                  rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
                  nativeCurrency: {
                    name: "BNB",
                    symbol: "BNB",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://testnet.bscscan.com/"],
                },
              ],
            });
            console.log("Network added successfully");
          } catch (addError) {
            console.error("Failed to add network", addError);
          }
        } else {
          console.error("Failed to switch network", switchError);
        }
      }
    } else {
      console.log("MetaMask is not installed");
    }
  };
  SwitchNetwork(97);

  // for Join Octa Smart Community today !
  const Joining = async (packageValue) => {
    if (!address) {
      alert(
        "We only support BNB Smart Chain Mainnet right now. Please switch to Binance Network and Connect."
      );
      return;
    }
    const usdtValue = Web3.utils.toWei(packageValue.toString(), "ether");
    const refferedby = document.getElementById("refferedby").value;
    if (refferedby === "") {
      alert("Sponsor Address Required!");
      return;
    }
    try {
      const result = await contractInstance.methods
        .register(refferedby, usdtValue)
        .send({ from: address });
      console.log("Registration successful:", result);
    } catch (error) {
      console.error("Error during registration:", error);
    }
  };
  const PreAuthorize = async (packageValue) => {
    if (!address) {
      await SwitchNetwork();
      return;
    }
    const usdtValue = Web3.utils.toWei(packageValue.toString(), "ether");
    try {
      const result = await bscUsdContract.methods
        .approve(contractAddress, usdtValue)
        .send({ from: address });
      console.log("Approval successful:", result);
      setTimeout(async () => {
        await Joining(packageValue);
      }, 2000);
    } catch (error) {
      console.error("Error during approval:", error);
    }
  };

  // for ReTopup buttons in Basic Packages
  const ReTopup = async (packageValue, packageId) => {
    if (!address) {
      alert(
        "Alert! We only support BNB Smart Chain Mainnet right now. Please switch to Binance Network and Connect."
      );
      return false;
    }
    try {
      const usdtValue = Web3.utils.toWei(packageValue.toString(), "ether");
      const response = await contractInstance.methods
        .reTopUp(packageId, usdtValue)
        .send({ from: address });
      console.log("Success Section =", response);
      return true;
    } catch (error) {
      console.error("Error Section =", error);
      return false;
    }
  };
  const PreAuthorizeReTopupClick = async (packageValue, packageId) => {
    if (!address) {
      await SwitchNetwork();
      return;
    }
    try {
      const usdtValue = Web3.utils.toWei(packageValue.toString(), "ether");
      const response = await bscUsdContract.methods
        .approve(contractAddress, usdtValue)
        .send({ from: address });
      console.log(response);
      setTimeout(async () => {
        await ReTopup(packageValue, packageId);
      }, 2000);
    } catch (error) {
      console.error(error);
    }
  };

  // for Purchase buttons in Octa De El-Classico
  const UpgradeProPool = async (proPoolId, packageValue) => {
    try {
      if (!address) {
        alert(
          "Alert! We only support BNB Smart Chain Mainnet right now. Please switch to Binance Network and Connect."
        );
        return false;
      }
      const usdtValue = Web3.utils.toWei(packageValue.toString(), "ether");
      const result = await contractInstance.methods
        .goProPool(proPoolId, usdtValue)
        .send({ from: address });
      console.log("Success Section =", result);
      return true;
    } catch (error) {
      console.error("Error Section =", error);
      return false;
    }
  };
  const PreAuthorizeUpgrade = async (proPoolId, packageValue) => {
    try {
      if (!address) {
        await SwitchNetwork();
      }
      const usdtValue = Web3.utils.toWei(packageValue.toString(), "ether");
      const result = await bscUsdContract.methods
        .approve(contractAddress, usdtValue)
        .send({ from: address });
      console.log(result);
      setTimeout(async () => {
        await UpgradeProPool(proPoolId, packageValue);
      }, 2000);
    } catch (error) {
      console.error(error);
    }
  };

  // Claim now buttons Pro Monthly Bonus in Octa De El-Classico
  const ClaimProMonthlyBonus = async (proPoolId) => {
    if (!address) {
      alert("Please connect your wallet and switch to Binance Smart Chain.");
      return;
    }
    try {
      const result = await contractInstance.methods
        .claimProMonthlyBonus(proPoolId)
        .send({ from: address });
      console.log("Success:", result);
      return true;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  };

  // for purchased Octa De El-Classico packages
  const GetUserProPoolSummary = async (proPoolId) => {
    try {
      const res = await contractInstance.methods
        .pro_pool_history(address, proPoolId)
        .call();
      const updatedData = { ...proPoolData };
      const proPoolIndex = parseInt(proPoolId);

      // Check if the pool has been purchased
      if (res[0] === "true" || res[0] === true) {
        const claimedMonths = parseInt(res[3]) || 0;
        const remainingMonth = 9 - claimedMonths;
        const claimedDays = parseInt(res[4]) || 0;
        const remainingDays = Math.max(30 - claimedDays, 0);

        updatedData.purchasePoolFlag[proPoolIndex] = 1;
        updatedData.purchasePoolBtn[proPoolIndex] = 1;
        updatedData.remainingMonth[proPoolIndex] = remainingMonth;
        updatedData.claimedMonth[proPoolIndex] = claimedMonths;

        // Claimable logic based on months and days
        if (claimedMonths > 0) {
          updatedData.remainingDays[proPoolIndex] = remainingDays;
          if (remainingDays === 0 && claimedMonths < 9) {
            updatedData.claimable[proPoolIndex] = true;
          } else if (remainingDays > 0) {
            updatedData.claimable[proPoolIndex] = false;
          }
        } else {
          updatedData.remainingDays[proPoolIndex] =
            claimedDays >= 30 ? 0 : 30 - claimedDays;
          updatedData.claimable[proPoolIndex] = claimedDays >= 30;
        }
      } else {
        // Handle the "not purchased" case
        if (proPoolId === 0) {
          if (noOfDirect < 3) {
            updatedData.purchasePoolFlag[proPoolIndex] = 0;
            updatedData.purchasePoolBtn[proPoolIndex] = 1;
          } else {
            updatedData.purchasePoolFlag[proPoolIndex] = 1;
            updatedData.purchasePoolBtn[proPoolIndex] = 0;
          }
        } else if (proPoolId >= 1) {
          const previousproPoolIndex = proPoolIndex - 1;

          if (
            updatedData.purchasePoolFlag[previousproPoolIndex] === 1 &&
            updatedData.remainingMonth[previousproPoolIndex] > 0
          ) {
            updatedData.purchasePoolFlag[proPoolIndex] = 1;
            updatedData.purchasePoolBtn[proPoolIndex] = 0;
          } else {
            updatedData.purchasePoolFlag[proPoolIndex] = 0;
            updatedData.purchasePoolBtn[proPoolIndex] = 1;
          }
        }
      }
      setProPoolData(updatedData);
    } catch (error) {
      // console.error("Error fetching pro pool history:", error);
    }
  };

  const BindProPoolData = async () => {
    for (let i = 0; i <= 8; i++) {
      await GetUserProPoolSummary(i);
    }
  };
  BindProPoolData();

  // for Purchase buttons in Spanish Fiesta Packages
  const UpgradeSpanishFiesta = async (spanishFiestaPoolId, packageValue) => {
    try {
      if (!address) {
        alert(
          "Alert! We only support BNB Smart Chain Mainnet right now. Please switch to Binance Network and Connect."
        );
        return false;
      }
      const usdtValue = Web3.utils.toWei(packageValue.toString(), "ether");
      const result = await contractInstance.methods
        .goSpanishFiestaPool(spanishFiestaPoolId, usdtValue)
        .send({ from: address });
      console.log("Success Section =", result);
      return true;
    } catch (error) {
      console.error("Error Section =", error);
      return false;
    }
  };
  const PreAuthorizeSpanishFiesta = async (
    spanishFiestaPoolId,
    packageValue
  ) => {
    try {
      if (!address) {
        await SwitchNetwork();
      }
      const usdtValue = Web3.utils.toWei(packageValue.toString(), "ether");
      const result = await bscUsdContract.methods
        .approve(contractAddress, usdtValue)
        .send({ from: address });
      console.log(result);
      setTimeout(async () => {
        await UpgradeSpanishFiesta(spanishFiestaPoolId, packageValue);
      }, 2000);
    } catch (error) {
      console.error(error);
    }
  };
  // Claim now buttons Pro Monthly Bonus in Spanish Fiesta Packages
  const ClaimSpanishFiestaMonthlyBonus = async (spanishFiestaPoolId) => {
    try {
      if (!address) {
        alert(
          "Alert! We only support BNB Smart Chain Mainnet right now. Please switch to Binance Network and connect your wallet."
        );
        return false;
      }
      const result = await contractInstance.methods
        .claimSpanishFiestaMonthlyBonus(spanishFiestaPoolId)
        .send({ from: address });
      console.log("Success Section =", result);
      return true;
    } catch (error) {
      console.error("Error Section =", error);
      return false;
    }
  };

  // for purchased Spanish Fiesta Packages
  const GetUserSpanishFiestaPackSummary = async (spanishFiestaPoolId) => {
    try {
      const res = await contractInstance.methods
        .spanish_fiesta_pool_history(address, spanishFiestaPoolId)
        .call();

      const updatedData = { ...spanishFiestaPackData };
      const poolIndex = parseInt(spanishFiestaPoolId);

      // Check if the pool has been purchased
      if (res[0] === "true" || res[0] === true) {
        const claimedMonths = parseInt(res[3]) || 0;
        const remainingMonth = 8 - claimedMonths;
        const claimedDays = parseInt(res[4]) || 0;
        const remainingDays = Math.max(30 - claimedDays, 0);

        updatedData.SpanishFiestaPackFlag[poolIndex] = 1;
        updatedData.SpanishFiestaPackBtn[poolIndex] = 1;
        updatedData.remainingMonth[poolIndex] = remainingMonth;
        updatedData.claimedMonth[poolIndex] = claimedMonths;

        // Claimable logic based on months and days
        if (claimedMonths > 0) {
          updatedData.remainingDays[poolIndex] = remainingDays;
          if (remainingDays === 0 && claimedMonths < 8) {
            updatedData.claimable[poolIndex] = true;
          } else if (remainingDays > 0) {
            updatedData.claimable[poolIndex] = false;
          }
        } else {
          updatedData.remainingDays[poolIndex] =
            claimedDays >= 30 ? 0 : 30 - claimedDays;
          updatedData.claimable[poolIndex] = claimedDays >= 30;
        }
      } else {
        // Handle the "not purchased" case
        if (spanishFiestaPoolId === 0) {
          if (noOfDirect < 3) {
            updatedData.SpanishFiestaPackFlag[poolIndex] = 0;
            updatedData.SpanishFiestaPackBtn[poolIndex] = 1;
          } else {
            updatedData.SpanishFiestaPackFlag[poolIndex] = 1;
            updatedData.SpanishFiestaPackBtn[poolIndex] = 0;
          }
        } else if (spanishFiestaPoolId >= 1) {
          const previousPoolIndex = poolIndex - 1;

          if (
            updatedData.SpanishFiestaPackFlag[previousPoolIndex] === 1 &&
            updatedData.remainingMonth[previousPoolIndex] > 0
          ) {
            updatedData.SpanishFiestaPackFlag[poolIndex] = 1;
            updatedData.SpanishFiestaPackBtn[poolIndex] = 0;
          } else {
            updatedData.SpanishFiestaPackFlag[poolIndex] = 0;
            updatedData.SpanishFiestaPackBtn[poolIndex] = 1;
          }
        }
      }
      setSpanishFiestaPackData(updatedData);
    } catch (error) {
      // console.error("Error fetching pro pool history:", error);
    }
  };

  const BindspanishFiestaPackData = async () => {
    for (let i = 0; i <= 8; i++) {
      await GetUserSpanishFiestaPackSummary(i);
    }
  };
  BindspanishFiestaPackData();

  // for Claim now in Diamond Worth
  const SellDiamond = async () => {
    try {
      if (!address) {
        alert(
          "Alert! We only support BNB Smart Chain Mainnet right now. Please switch to the Binance Network and connect your wallet."
        );
        return false;
      }
      const result = await contractInstance.methods
        .sellOutDiamond()
        .send({ from: address });
      console.log("Success Section =", result);
      return true;
    } catch (error) {
      console.error("Error Section =", error);
      return false;
    }
  };

  return (
    <div>
      {status === "connected" ? (
        <>
          <Header address={address} bnbBalance={bnbBalance} />
          <JoinOcta PreAuthorize={PreAuthorize} isJoined={isJoined} />
          <AffiliateDetails
            address={address}
            bscUsdBalance={bscUsdBalance}
            bnbBalance={bnbBalance}
            userData={userData}
            usersBonusData={usersBonusData}
            rankIcon={rankIcon}
          />
          <ReTopupEarnings
            PreAuthorizeReTopupClick={PreAuthorizeReTopupClick}
            isReTopupVisible={isReTopupVisible}
          />
          <OctaDeElClassico
            isProPackgeVisible={isProPackgeVisible}
            PreAuthorizeUpgrade={PreAuthorizeUpgrade}
            ClaimProMonthlyBonus={ClaimProMonthlyBonus}
            proPoolData={proPoolData}
          />
          <SpanishFiestaPackages
            isFiestaVisible={isFiestaVisible}
            PreAuthorizeSpanishFiesta={PreAuthorizeSpanishFiesta}
            ClaimSpanishFiestaMonthlyBonus={ClaimSpanishFiestaMonthlyBonus}
            spanishFiestaPackData={spanishFiestaPackData}
          />
          <YourBonusSummary
            userData={userData}
            usersBonusData={usersBonusData}
            userPurchaseData={userPurchaseData}
            diamondworth={diamondworth}
            percent={percent}
            chartRef={chartRef}
            myTeamData={myTeamData}
            levelDownlineStats={levelDownlineStats}
            rankId={userData?.rankId}
            SellDiamond={SellDiamond}
          />
        </>
      ) : (
        <>
          <Header address={address} bnbBalance={bnbBalance} />
          <JoinOcta PreAuthorize={PreAuthorize} isJoined={isJoined} />
          <AffiliateDetails
            address={address}
            bscUsdBalance={bscUsdBalance}
            bnbBalance={bnbBalance}
            userData={userData}
            usersBonusData={usersBonusData}
          />
          <ReTopupEarnings
            PreAuthorizeReTopupClick={PreAuthorizeReTopupClick}
            isReTopupVisible={isReTopupVisible}
          />
          <OctaDeElClassico
            isProPackgeVisible={isProPackgeVisible}
            PreAuthorizeUpgrade={PreAuthorizeUpgrade}
            ClaimProMonthlyBonus={ClaimProMonthlyBonus}
            proPoolData={proPoolData}
          />
          <SpanishFiestaPackages
            isFiestaVisible={isFiestaVisible}
            PreAuthorizeSpanishFiesta={PreAuthorizeSpanishFiesta}
            ClaimSpanishFiestaMonthlyBonus={ClaimSpanishFiestaMonthlyBonus}
            spanishFiestaPackData={spanishFiestaPackData}
          />
          <YourBonusSummary
            userData={userData}
            usersBonusData={usersBonusData}
            userPurchaseData={userPurchaseData}
            diamondworth={diamondworth}
            percent={percent}
            chartRef={chartRef}
            myTeamData={myTeamData}
            levelDownlineStats={levelDownlineStats}
            rankId={userData?.rankId}
            SellDiamond={SellDiamond}
          />
        </>
      )}
    </div>
  );
};

export default WagmiHooks;
