import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import logo from "../assets/img/logo.png";
import { createAppKit } from "@reown/appkit/react";
import { WagmiProvider } from "wagmi";

// Import supported networks
import {
  mainnet,
  polygon,
  acala,
  chiliz,
  berachainTestnetbArtio,
  sepolia,
} from "@reown/appkit/networks";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiAdapter } from "@reown/appkit-adapter-wagmi";

// Setup queryClient for react-query
const queryClient = new QueryClient();

// Fetch the project ID from environment variables
const projectId = process.env.REACT_APP_PROJECT_ID; // Updated for React.js
if (!projectId) {
  throw new Error("REACT_APP_PROJECT_ID is not set");
}

// // Define the BSC network object
// const bsc = {
//   id: 56, // Chain ID for BSC mainnet
//   name: "Binance Smart Chain",
//   rpcUrls: ["https://bsc-dataseed.binance.org/"], // RPC endpoint
//   explorer: {
//     url: "https://bscscan.com", // BSCScan URL
//     name: "BscScan",
//   },
//   nativeCurrency: {
//     name: "Binance Coin",
//     symbol: "BNB",
//     decimals: 18,
//   },
// };

// Define the BSC Testnet network object
const bscTestnet = {
  id: 97, // Chain ID for BSC Testnet
  name: "Binance Smart Chain Testnet",
  rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
  explorer: {
    url: "https://testnet.bscscan.com",
    name: "BscScan Testnet",
  },
  nativeCurrency: {
    name: "Binance Coin",
    symbol: "BNB",
    decimals: 18,
  },
};

// Define supported networks
const networks = [
  // bsc,
  bscTestnet,
  mainnet,
  polygon,
  acala,
  chiliz,
  berachainTestnetbArtio,
  sepolia,
];

// General configuration object
const generalConfig = {
  projectId,
  // metadata,
  networks,
};

// Create Wagmi Adapter
const wagmiAdapter = new WagmiAdapter(generalConfig);

// Create AppKit modal
const modal = createAppKit({
  adapters: [wagmiAdapter],
  ...generalConfig,
});

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-2">
            <div className="header__logo">
              <a href="/">
                <img src={logo} alt="logo" />
              </a>
            </div>
          </div>
          <div className="col-lg-10">
            <div
              className={`header__nav__option ${menuOpen ? "menu-open" : ""}`}
            >
              <nav className="header__nav__menu mobile-menu">
                <ul className="stat-btn">
                  <li>
                    <button
                      className="new_joining_btn"
                      onClick={() =>
                        window.open(
                          "https://testnet.bscscan.com/address/0x2323e42eb8972b9a91e337db51aae6a50e853ee7",
                          "_blank",
                          "noopener noreferrer"
                        )
                      }
                    >
                      Smart Contract
                      <div className="hover_shape_wrapper">
                        <span className="btn_hover_shape btn_hover_shape-1" />
                        <span className="btn_hover_shape btn_hover_shape-2" />
                        <span className="btn_hover_shape btn_hover_shape-3" />
                      </div>
                    </button>
                  </li>
                  <div>
                    <WagmiProvider config={wagmiAdapter.wagmiConfig}>
                      <QueryClientProvider client={queryClient}>
                        {/* Web3Modal Button */}
                        <button className="new_joining_btn">
                          <span>
                            <w3m-button />
                          </span>
                        </button>
                      </QueryClientProvider>
                    </WagmiProvider>
                  </div>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div id="mobile-menu-wrap" />
      </div>
      <button onClick={toggleMenu} className="menu-btn">
        <FaBars className="fa-solid" />
      </button>
    </header>
  );
};

export default Header;
