import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import WagmiHooks from "../pages/WagmiHooks";
import DiamanteOcta from "../pages/DiamanteOcta";

const CurrentPath = () => {
  const location = useLocation();

  useEffect(() => {
    // Dynamically update the document title based on the current path
    switch (location.pathname) {
      case "/":
        document.title = "Home"; // Title for the WagmiHooks page
        break;
      case "/diamante":
        document.title = "Diamante"; // Title for the DiamanteOcta page
        break;
      default:
        document.title = "My App"; // Fallback title
    }
  }, [location]);

  return null; // No UI rendering
};

const Router = () => {
  return (
    <BrowserRouter>
      {/* Dynamically update the title based on the current route */}
      <CurrentPath />

      <Routes>
        <Route path="/" element={<WagmiHooks />} />
        <Route path="/diamante" element={<DiamanteOcta />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
